import { planesToArray } from "@/modes/clipping-box-mode/planes-to-array";
import { IQuat, IVec3 } from "@faro-lotv/ielement-types";
import { memberWithPrivateData, SupportedCamera } from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  convertThreeToIElementTransform,
} from "@faro-lotv/project-source";
import {
  Euler,
  Matrix4,
  OrthographicCamera,
  Plane,
  Quaternion,
  Vector3,
} from "three";

/** Extra padding(m) added to the camera framing */
const CAMERA_MARGIN = 0.1;

export type VolumeInfo = {
  /** Position of the volume */
  position: IVec3 | null;

  /** Rotation of the volume */
  rotation: IQuat | null;

  /** Sizes of the volume box */
  size: IVec3 | null;
};

/** Class used to ensure that the allocated objects are removed when the component is unmounted */
export class VolumeUtils {
  createClippingPlanes = memberWithPrivateData(() => {
    const mat = new Matrix4();
    const planes = [
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
      new Plane(),
    ];

    return (clippingPlanes: Plane[], transform: CachedWorldTransform) => {
      mat.fromArray(transform.worldMatrix);

      planes.forEach((plane, i) => {
        plane.copy(clippingPlanes[i]);
        plane.applyMatrix4(mat);
      });

      return [...planes];
    };
  });

  createClippingPlanesMatrix = memberWithPrivateData(() => {
    const mat = new Matrix4();
    return (clippingPlanes: Plane[]) =>
      // The generated matrix from the function is row-major because it is used by the backend,
      // three.js uses column-major matrices, so the matrix is transposed
      mat.fromArray(planesToArray(clippingPlanes)).transpose();
  });

  frameCameraOnPointCloud = memberWithPrivateData(() => {
    const xAxis = new Vector3();
    const yAxis = new Vector3();
    const zAxis = new Vector3();
    const center = new Vector3();
    const offset = new Vector3();
    const euler = new Euler();
    const mat1 = new Matrix4();
    const mat2 = new Matrix4();
    const mat3 = new Matrix4();

    return (
      camera: SupportedCamera,
      matrix: Matrix4,
      size: { width: number; height: number },
      onAspectRatioChanged?: (aspectRatio: number) => void,
    ) => {
      // First three columns of the matrix are the rotation with the scale
      // But they also are the three directions of the sides of the box
      matrix.extractBasis(xAxis, yAxis, zAxis);

      const xSize = xAxis.length();
      const ySize = yAxis.length();
      const zSize = zAxis.length();

      onAspectRatioChanged?.(xSize / zSize);

      // The matrix's position is a bottom corner of the box
      // By adding to it half of each side, we get the center of the box
      center
        .setFromMatrixPosition(matrix)
        .add(xAxis.multiplyScalar(0.5))
        .add(yAxis.multiplyScalar(0.5))
        .add(zAxis.multiplyScalar(0.5));

      // Place the camera above the center of the box
      camera.position
        .copy(center)
        .add(offset.set(0, ySize / 2 + CAMERA_MARGIN, 0));
      // Make the camera look down at the center of the box
      camera.lookAt(center);

      euler.setFromRotationMatrix(matrix, "YZX");
      const pos = camera.position;

      mat1.makeTranslation(pos.x, pos.y, pos.z);
      mat2.makeRotationY(euler.y);
      mat3.makeTranslation(-pos.x, -pos.y, -pos.z);
      const mat4 = mat1.multiply(mat2).multiply(mat3);

      camera.applyMatrix4(mat4);

      // The camera is centered so that the entire clipping box is always visible
      if (camera instanceof OrthographicCamera) {
        const aspectRatio = size.width / size.height;
        if (xSize / aspectRatio > zSize) {
          camera.right = xSize / 2 + CAMERA_MARGIN;
          camera.left = -camera.right;
          camera.top = camera.right / aspectRatio;
          camera.bottom = -camera.top;
        } else {
          camera.top = zSize / 2 + CAMERA_MARGIN;
          camera.bottom = -camera.top;
          camera.right = camera.top * aspectRatio;
          camera.left = -camera.right;
        }
        camera.near = CAMERA_MARGIN;
        camera.far = ySize + 2 * CAMERA_MARGIN;
      }

      camera.updateProjectionMatrix();
    };
  });

  volumeFromPlanes = memberWithPrivateData(() => {
    const position = new Vector3();
    const quaternion = new Quaternion();
    const scale = new Vector3();

    return (
      clippingPlanes?: Plane[],
      matrix?: CachedWorldTransform,
    ): VolumeInfo | undefined => {
      if (!clippingPlanes || !matrix) return;

      const worldClippingPlanes = this.createClippingPlanes(
        clippingPlanes,
        matrix,
      );
      const worldClippingPlanesMatrix =
        this.createClippingPlanesMatrix(worldClippingPlanes);
      worldClippingPlanesMatrix.decompose(position, quaternion, scale);

      const iElementTransform = convertThreeToIElementTransform({
        position,
        quaternion,
        scale,
      });

      // Convert from right-handed to left-handed
      return {
        // The volume position is the min point of the box
        position: iElementTransform.pos
          ? {
              x: iElementTransform.pos.x,
              y: iElementTransform.pos.y,
              z: iElementTransform.pos.z * -1,
            }
          : null,
        rotation: iElementTransform.rot
          ? {
              x: iElementTransform.rot.x,
              y: iElementTransform.rot.y,
              z: iElementTransform.rot.z * -1,
              w: iElementTransform.rot.w * -1,
            }
          : null,
        size: iElementTransform.scale
          ? {
              x: iElementTransform.scale.x,
              y: iElementTransform.scale.y,
              z: iElementTransform.scale.z,
            }
          : null,
      };
    };
  });
}
